/*
 *  Image Stacks
 *	Initially developed for use on JamesMoes.com
 *
 *  Hai Studio
 *  http://hai-studio.com
 *	Tobias Toft, 2017
 */


module.exports = Stack;


function Stack(element, options){
    if (!(this instanceof Stack)) return new Stack(options);

    this.options = $.extend( true, {}, this.options, options );
    this.container = $(element);

    this.images = [];
		this.index = 0;
		this.lastDroppedMousePos = {x:0, y:0};
		this.lastMotionPos = {x:0, y:0};
    this.motionCoords = {  x : this.container.width()/2,
                           y : this.container.outerHeight()/2,
                          vx : 0,
                          vy : 0,
                          ax : 0,
                          ay : 0
    };

    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var bottomThreshold = $(window).height()/2;

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom-bottomThreshold > viewportTop && elementTop < viewportBottom;
    };

    this._init();
}


Stack.prototype._init = function(){
	console.log("Init new stack");
	this.images = this.container.find('.stack-img');

  //Check how many images are visible, and start the stack at that index
  var count = 0;
  for (var i=0; i<this.images.length; i++){
    if ($(this.images[i]).css('display') == 'block'){
      count++;
    }
  }

  this.index = count;

	this.attachMouseListener();

  if (this.isMobile()){
    console.log("Looks like we're on mobile, attaching motion event listener.");
    this.attachMotionListener();
  } else {
    console.log("Looks like we're not on mobile.");
  }
}

Stack.prototype.options = {
	threshold: 250,
  loopImages: true,
  snapToGrid: true,
  gridCols: 12,
  edgeRandMin: 0, //px
  edgeRandMax: 25, //px
  sizeRandLB: 0.25,
  sizeRandUB: 0.38,
  maxHeightThreshold: 0.8, //fraction of container height
  maxHeightThresholdScale: 0.7, //scale of container height, if above threshold
  motionUpdateThreshold: 80, //How much movement before we drop?
  motionFriction: 0.85, //How much friction? The higher the less
  motionCenterTension: 0.01, //How hard to we want to try centered? The closer to 1 the harder.
  mobileImageScale: 1.5 //How much larger should the images be on mobile?
}

Stack.prototype.attachMotionListener = function(){
  var options = this.options;
  var parent = this;

  document.addEventListener('gyronormUpdate', function(e){
    var motion = e.detail;

    if (motion.status === 'ok'){
      if (!parent.container.isInViewport()){
        return;
      }

      var ax = motion.data.dm.beta * 3;
      var ay = -motion.data.dm.alpha * 3;

      var landscapeOrientation = window.innerWidth/window.innerHeight > 1;
      if (landscapeOrientation) {
        parent.motionCoords.vx = parent.motionCoords.vx + ay;
        parent.motionCoords.vy = parent.motionCoords.vy + ax;
      } else {
        parent.motionCoords.vy = parent.motionCoords.vy - ay;
        parent.motionCoords.vx = parent.motionCoords.vx + ax;
      }
      parent.motionCoords.vx = parent.motionCoords.vx * options.motionFriction;
      parent.motionCoords.vy = parent.motionCoords.vy * options.motionFriction;
      parent.motionCoords.y = parseInt(parent.motionCoords.y + parent.motionCoords.vy / 50);
      parent.motionCoords.x = parseInt(parent.motionCoords.x + parent.motionCoords.vx / 50);

      var dx = (parent.container.width()/2) - parent.motionCoords.x;
      var dy = (parent.container.outerHeight()/2) - parent.motionCoords.y;

      parent.motionCoords.y = parent.motionCoords.y + dy * options.motionCenterTension;
      parent.motionCoords.x = parent.motionCoords.x + dx * options.motionCenterTension;

      var accDist = Math.sqrt( Math.pow((parent.motionCoords.x-parent.lastMotionPos.x), 2) + Math.pow((parent.motionCoords.y-parent.lastMotionPos.y), 2) );

      boundingBoxCheck();

      var offset = parent.container.offset();
      if (accDist > options.motionUpdateThreshold){
        parent.lastMotionPos.x = parent.motionCoords.x;
        parent.lastMotionPos.y = parent.motionCoords.y;

        parent.dropImage(parent.motionCoords.x, parent.motionCoords.y);
      }
    }

    //console.log('Device moved: ' + accDist + 'px');
  });

  function boundingBoxCheck(){
    if (parent.motionCoords.x<0) { parent.motionCoords.x = 0; parent.motionCoords.vx = -parent.motionCoords.vx; }
    if (parent.motionCoords.y<0) { parent.motionCoords.y = 0; parent.motionCoords.vy = -parent.motionCoords.vy; }
    if (parent.motionCoords.x>document.documentElement.clientWidth-20) { parent.motionCoords.x = document.documentElement.clientWidth-20; parent.motionCoords.vx = -parent.motionCoords.vx; }
    if (parent.motionCoords.y>document.documentElement.clientHeight-20) { parent.motionCoords.y = document.documentElement.clientHeight-20; parent.motionCoords.vy = -parent.motionCoords.vy; }

  }
}

Stack.prototype.attachMouseListener = function() {
	var options = this.options;
	var parent = this;
  this.container.mousemove(function(event) {
    var offset = parent.container.offset();
    var dist = Math.sqrt( Math.pow((event.pageX-parent.lastDroppedMousePos.x), 2) + Math.pow((event.pageY-parent.lastDroppedMousePos.y), 2) );

    var threshold = parent.isMobile() ? options.threshold/2 : options.threshold;

    if (dist > threshold){
      console.log("Moved " + dist + "px to " + event.pageX + "," + event.pageY);
      if (options.snapToGrid){
        var stepX = parent.container.width()/options.gridCols;
        var stepY = stepX;

        var imgX = stepX*Math.round((event.pageX-offset.left)/stepX);
        var imgY = stepY*Math.round((event.pageY-offset.top)/stepY);

        parent.dropImage(imgX, event.pageY-offset.top);
      } else {
        parent.dropImage(event.pageX-offset.left, event.pageY-offset.top);
      }
      parent.lastDroppedMousePos.x = event.pageX;
      parent.lastDroppedMousePos.y = event.pageY;
    }
  });
}

Stack.prototype.dropImage = function(x, y){
  console.log("Drop image at (" + x + ", " + y + ")");

  //Are we not looping, and if so, are we at the end of the list?
  if (!this.options.loopImages && this.index == this.images.length-1){
    return;
  }

  var id = this.images[this.index]; //image container div
  //var img = document.querySelector('#' + this.images[this.index].id + ' img'); //inner image
  var imgContainer = document.querySelector('#' + this.images[this.index].id);
  var imgWidth = parseInt(imgContainer.getAttribute('data-original-width'));
  var imgHeight = parseInt(imgContainer.getAttribute('data-original-height'));
  var randLB = this.options.sizeRandLB; //lower bound for random size
  var randUB = this.options.sizeRandUB; //upper bound for random size

  if (this.isMobile()){
    randLB = randLB * this.options.mobileImageScale;
    randUB = randUB * this.options.mobileImageScale;
  }

  if ($(id).length > 0){
    var containerWidth = this.container.width();
    var containerHeight = this.container.height();

    var aspect = imgWidth / imgHeight; //img.width/img.height;
    var randScale = _.random(randLB, randUB)
    var width = containerWidth * randScale;
    var height = width / aspect; //img.height * (width/img.width);

    console.log('Aspect: ' + aspect + ' --> Original (W,H): (' + imgWidth + 'px,' + imgHeight+ 'px), scale (' + randScale + ') to --> (W,H): (' + width + 'px,' + height + 'px)');

    //If portrait aspect
    if (aspect < 1){
      height = _.random(containerHeight * randLB, containerHeight * randUB);
      width = imgWidth * (height/imgHeight);
    }

    //If we're close to being the height of the container (within height * maxHeightThreshold),
    //scale down by a set number (maxHeightThresholdScale)
    if (height > containerHeight * this.options.maxHeightThreshold){
      width = width * this.options.maxHeightThresholdScale;
      height = height * this.options.maxHeightThresholdScale;
    }

    var edgeRandMin = this.options.edgeRandMin;
    var edgeRandMax = this.options.edgeRandMax;

    var newX = _.clamp(x, width/2+_.random(edgeRandMin,edgeRandMax), containerWidth-(width/2)-_.random(edgeRandMin,edgeRandMax));
    var newY = _.clamp(y, height/2+_.random(edgeRandMin,edgeRandMax), containerHeight-(height/2)-_.random(edgeRandMin,edgeRandMax));

    console.log($(this.container).attr('id') + " --> New position at (" + newX + ", " + newY + ")");
    console.log('Y+H: ' + (newY + height) + ' / ' + containerHeight);
    console.log('X: ' + newX + ' / ' + containerWidth);

    var newXPct = newX / containerWidth * 100;
    var newYPct = newY / containerHeight * 100;
    var widthPct = width / containerWidth * 100;
    var heightPct = height / containerHeight * 100;

    console.log('X: ' + newXPct + '%');
    console.log('Y: ' + newYPct + '%');

    $(id).css({'left': newXPct+'%',
               'top': newYPct+'%',
               'width': widthPct + '%',
               'height': heightPct + '%',
               'margin-left': -width/2+'px',
               'margin-top': -height/2+'px',
               'display': 'block'});

    this.container.append($(id));
  }

  this.lastDroppedMousePos.x = x;
  this.lastDroppedMousePos.y = y;
  if (this.options.loopImages){
    this.index = (this.index+1)%this.images.length;
  } else {
    this.index = _.clamp(this.index+1, 0, this.images.length-1);
  }
}

Stack.prototype.isMobile = function() {
  return (navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    navigator.userAgent.match(/Opera Mini/i) ||
    navigator.userAgent.match(/IEMobile/i));
}
