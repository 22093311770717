/*
 *  Main logic and bundling for JamesMoes.com
 *
 *  See also:
 *    - stack.js
 *    - ministack.js
 *  
 *  Apologies for the mess to any future developers who might look at this code,
 *  I'm about to have a baby, so suddenly clean code matters less to me. Hopefully 
 *  it's just a temporary character flaw.
 *    
 *  Hai Studio 
 *  http://hai-studio.com
 *  Tobias Toft, 2017
 */

global.$ = global.jQuery = require('jquery');
var jQueryBridget = require('jquery-bridget');

global._ = require('lodash');

require('what-input');
require('foundation-sites');


//Lazysizes
require('lazysizes');

//Typogr
global.typogr = require('typogr');

//ImagesLoaded
var imagesLoaded = require('imagesloaded');
jQueryBridget( 'imagesloaded', imagesLoaded, $ );

//Isotope/Masonry
var Isotope = require('isotope-layout');
require('./isotope.fitRows.centered.js');
jQueryBridget( 'isotope', Isotope, $ );

//Modernizr
var Modernizr = require('./modernizr-custom.js');

//Stickybits
var scrolldir = require('scrolldir');
var stickybits = require('stickybits');

//Scrollsnap
var scrollSnap = require('./scrollsnap.js');
global.scrollSnap = scrollSnap;

//Gyronorm, needed for Stacks on mobile
var GyroNorm = require('./gyronorm.complete.min.js').GyroNorm; 

//Stacks
var Stack = require('./stack.js');
jQueryBridget( 'stack', Stack, $ );

//Mini Stacks
var MiniStack = require('./ministack.js');
jQueryBridget( 'miniStack', MiniStack, $ );



//Init foundation
$(document).foundation();


//Set up event listeners
$(document).ready(function(){

  //Fix typography
  $('.typogrify p').each(function(elm){
    $(this).html(
      typogr.typogrify(
        $(this).html()
      )
    );
  });

  //Wedding & Portraits - check if hero image has been loaded
  $('.hero-image').imagesLoaded( { background: true }, function() {
    console.log('Hero image background image loaded or element not present.');
    $('.hero-image').addClass('loaded');
  });


  //Render the Masonry grids
  $('.gallery').isotope({
    layoutMode: 'masonry',
    itemSelector: '.grid-image-container',
    masonry: {
      gutter: 20,
      columnWidth: 1,
      percentPosition: true,
      fitWidth: false
    },
    transitionDuration: 0,
    initLayout: true,
    resize: true,
    onLayout: function($elems, instance){
      $('.side-menu-left').foundation('reflow');
    }
  });

  $('.journal-images').isotope({
    layoutMode: 'masonry',
    itemSelector: '.grid-image-container',
    masonry: {
      gutter: 40,
      columnWidth: 1,
      percentPosition: true,
      fitWidth: false
    },
    
    transitionDuration: 0,
    initLayout: true,
    resize: true,
    onLayout: function($elems, instance){
      $('.side-menu-left').foundation('reflow');
    }
  });


  //Stick the nav and sidenav
  stickybits('.nav-container', {useStickyClasses: true, stickyBitStickyOffset: 0, stickyClass: 'shrink'});
  stickybits('.side-nav-container', {useStickyClasses: true, stickyBitStickyOffset: 0, stickyClass: 'sidebar-sticky'});
  if ($('.side-menu-left').length){
    $('.side-menu-left').foundation('reflow'); //make sure we trigger at least one reflow, if side menu is present
  }

  //Add a class to the body of the page, depending on whether the user scrolls up or down. Might be useful.
  scrolldir();

  //Set up scroll snapping on the home page
  if (Modernizr.scrollsnappoints) {
    //We're good on scroll snap points, but make sure
    console.log("Using native support for scroll snap points.");
  } else {
    //Quit if we're not on the 'home' page
    if (!$('body').hasClass('home')){
      return;
    }

    //Not natively supported
    console.log("Using polyfill for scroll snap points.");

    //Set custom easing function
    const easeInOutCubic = function(t, b, c, d) {
      // t = Current frame
      // b = Start-value
      // c = End-value
      // d = Duration

      if ((t/=d/2) < 1) return c/2*t*t*t + b;
      return c/2*((t-=2)*t*t + 2) + b;
    }

    //Init
    scrollSnap.init({
      // NodeList of snap-elements (required)
      // scrollSnap always snaps to the nearest element
      elements: document.querySelectorAll('.stack'),
      
      // Integer - Set a minimum window-size (required)
      // scrollSnap will be deactivated when the window is smaller than the given dimensions
      minWidth: 800,
      minHeight: 600,
      
      // Boolean - Deactivate scrollSnap on mobile devices (optional)
      detectMobile: true,
      
      // Boolean - Keyboard-navigation (optional)
      keyboard: true,
      
      // Integer - Snap-animation-speed (optional)
      // Higher = slower
      duration: 25,

      // How long do we wait before initiating the snap?
      scrollStartDelay: 1000,
      
      // Function - Set a custom timing-function for the snap-animation (optional)
      timing: easeInOutCubic

    });
  }

  //Add class to page if it's scrolled to 50px within the top
  //currently being used to hide and show the scroll indicator arrow on the front page.
  $(document).scroll(function() { 
    if($(window).scrollTop() < 50) {
      $('body').addClass('isAtTop');
    } else {
      $('body').removeClass('isAtTop');
    }
  });

  //Init Gyronorm and collect data
  var gn = new GyroNorm();
  gn.init({
    frequency: 50,
    gravityNormalized:true,
    orientationBase:GyroNorm.GAME,
    screenAdjusted:false
  }).then(function(){
    gn.start(function(data){
      //Dispatch custom event to anyone who's listening
      document.dispatchEvent(new CustomEvent("gyronormUpdate", {
        detail: {
          'status': 'ok',
          'data': data
        }
      }));
    });
  }).catch(function(e){
    // Catch if the DeviceOrientation or DeviceMotion is not supported by the browser or device
    console.log('Device motion not supported.');
  });

});


// Auto-center side menu on active item
$('.side-menu-left').on('update.zf.magellan', function(){ 
    if( $('.side-menu-left:hover').length != 0 ){
      return;
    } else {
      updateSideMenu(); 
    }
  }
);

$('.side-menu-left').on('mouseleave', _.debounce(function(){ 
    updateSideMenu(); 
  }, 100)
);

function updateSideMenu(){
  //Centers the side menu so the active item is always in the middle of the page
  var $sidemenu = $('.side-menu-left');
  try {
    var $sidemenuActive = $sidemenu.find('.is-active');
    var diff = ($(window).height() - $sidemenuActive.outerWidth())/2 - $sidemenuActive.position().top; //remove if we're never re-enabling centering
    //$sidemenu.css('margin-top', diff); //uncomment to enable centering on the active item
    $sidemenu.removeClass('none-active');
  } catch(e) {
    //$sidemenu.css('margin-top', 0); //uncomment to enable centering on the active item
    $sidemenu.addClass('none-active');
  }
}
