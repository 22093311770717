/*
 *  Mini Image Stacks
 *	Initially developed for use on JamesMoes.com
 *	
 *  Hai Studio 
 *  http://hai-studio.com
 *	Tobias Toft, 2017
 */

module.exports = MiniStack;


function MiniStack(element, options){
    if (!(this instanceof MiniStack)) return new MiniStack(options);

    this.options = $.extend( true, {}, this.options, options );
    this.container = $(element);

    this.images = [];
    this.count = 0;

    this._init();
}


MiniStack.prototype._init = function(){
	console.log("Init new mini stack");
	this.images = this.container.find('.mini-stack-img');
  this.count = this.images.length;

	this.attachMouseListeners();
}

MiniStack.prototype.options = {

}

MiniStack.prototype.attachMouseListeners = function() {
	var parent = this;
  this.container.mousemove(function(event) {
    var pX = event.pageX-parent.container.offset().left;
    var indexX = Math.round(pX * (parent.count-1) / parent.container.width());

    parent.showImage(indexX);
  });

  this.container.mouseleave(function(event) {
    parent.restoreToInitState();
  });
}

MiniStack.prototype.showImage = function(index){
  var id = this.images[index];

  if ($(id).length > 0){
    //this.container.find($('.mini-stack-img').not($(id))).css({'display': 'none'}); //hide other images
    $(id).css({'display': 'block'}); 
  }

  this.container.append($(id));
}

MiniStack.prototype.restoreToInitState = function(){
  console.log("Restore");
  for (var i=0; i<this.count; i++){
    var id = this.images[i];
    if (i==0){
      $(id).css({'display': 'block'}); 
    } else {
      $(id).css({'display': 'none'}); 
    }
  }
}
